import React from "react";
import {
  getDisplayId,
  getFormattedDate,
  parseFloatSafe,
} from "../../../shared/globalFunctions";

/* --- Project Imports ---*/

/* --- MUI Imports ---*/
import { Box, Typography, Divider } from "@mui/material";

import { InvoicesQueryResponse } from "../../../apis/internalDb/billing/billing";
import { USDollar } from "../../../shared/resources/referenceConstants";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

interface InvoicesProps {
  invoiceData: InvoicesQueryResponse[];
}

export default function Invoices({ invoiceData }: InvoicesProps) {
  const PAGE_SIZE = [15, 50, 100];
  /* --- React Query ---*/

  /* --- Handlers ---*/
  // const handleChange = (noteId: number) => {
  //   setShowInvoice(true);
  //   setShowInvoiceId(noteId);
  // };

  /* --- Other Stuff ---*/

  const columns: GridColDef[] = [
    {
      field: "invoice_date",
      headerName: "Invoice Date",
      width: 200,
      renderCell: (params) => (
        <>{getFormattedDate(new Date(params.row.invoice_date))}</>
      ),
    },
    {
      field: "invoice_id",
      headerName: "Invoice Number",
      width: 200,
      renderCell: (params) => (
        <>Invoice #{getDisplayId(params.row.invoice_id)}</>
      ),
    },
    {
      field: "original_client_amount_due",
      headerName: "Amount Charged",
      width: 150,
      renderCell: (params) => (
        <>
          {USDollar.format(
            parseFloatSafe(params.row.original_client_amount_due, 0)
          )}
        </>
      ),
    },
    {
      field: "total_client_amount_paid",
      headerName: "Payments",
      width: 150,
      renderCell: (params) => (
        <>
          {USDollar.format(
            parseFloatSafe(params.row.total_client_amount_paid, 0)
          )}
        </>
      ),
    },
    {
      field: "total_client_amount_due",
      headerName: "Balance",
      width: 150,
      renderCell: (params) => (
        <>
          {USDollar.format(
            parseFloatSafe(params.row.total_client_amount_due, 0)
          )}
        </>
      ),
    },
  ];

  return (
    <Box mt={2}>
      <Typography variant="h6">{`Invoices (${invoiceData.length})`}</Typography>
      <Divider />
      <Box mt={2}>
        <DataGrid
          rows={invoiceData}
          columns={columns}
          pageSizeOptions={PAGE_SIZE}
          autoHeight
          getRowId={(row) => row.invoice_id}
          sx={{
            fontSize: "medium",
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
          }}
        />
      </Box>
    </Box>
  );
}
