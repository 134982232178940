import React from "react";

import {
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import dayjs from "dayjs";
import { Link } from "react-router-dom";
import {
  RefDataInterface,
  USDollar,
} from "../../../shared/resources/referenceConstants";
import {
  PaymentHistoryResponseType,
  PaymentHistoryType,
} from "../../../apis/internalDb/billing/billing";
import { getDisplayId } from "../../../shared/globalFunctions";

const tableHeaderStyle = {
  fontSize: "0.9rem",
  paddingBottom: 2,
};

const tableCellStyle = {
  fontSize: "0.8rem",
  paddingLeft: 16,
  paddingRight: 0,
  paddingTop: 0,
  paddingBottom: 1,
  border: "none",
};

interface ShowPaymentDetailsProps {
  paymentInfo: PaymentHistoryResponseType;
  paymentMethodRefData: RefDataInterface[];
  historyByInvoice: boolean;
}

export default function ShowPaymentDetails({
  paymentInfo,
  paymentMethodRefData,
  historyByInvoice,
}: ShowPaymentDetailsProps) {
  /* --- React Query --- */

  /* --- Handlers --- */

  /* --- Formik --- */

  return (
    <>
      <Grid container spacing={2}>
        {paymentInfo.payment ? (
          <>
            <Grid item xs={12}>
              <Typography variant="h5">Details</Typography>
              <TableContainer>
                <Table
                  sx={{
                    minWidth: 500,
                    fontSize: 20,
                  }}
                  // size="small"
                  aria-label="a dense table"
                >
                  <colgroup>
                    <col width="16.66%" />
                    <col width="16.66%" />
                    <col width="16.66%" />
                    <col width="16.66%" />
                    <col width="16.66%" />
                    <col width="16.66%" />
                    <col width="16.66%" />
                  </colgroup>
                  <TableHead>
                    <TableRow>
                      <TableCell style={tableHeaderStyle}>
                        Payment Date
                      </TableCell>
                      <TableCell style={tableHeaderStyle}>Method</TableCell>
                      <TableCell style={tableHeaderStyle}>
                        Card Last 4 (if applicable)
                      </TableCell>
                      <TableCell style={tableHeaderStyle}>
                        Payment Amount
                      </TableCell>
                      <TableCell style={tableHeaderStyle}>
                        Amount Applied
                      </TableCell>
                      <TableCell style={tableHeaderStyle}>
                        Amount Refunded
                      </TableCell>
                      <TableCell style={tableHeaderStyle}>
                        Unallocated
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell style={tableCellStyle}>
                        {dayjs(paymentInfo.payment.payment_date).format(
                          "MM/DD/YY"
                        )}
                      </TableCell>
                      <TableCell style={tableCellStyle}>
                        {
                          paymentMethodRefData.find(
                            (pmrd: RefDataInterface) =>
                              pmrd.ref_id ===
                              paymentInfo.payment.payment_type_ref_id
                          )?.label
                        }
                      </TableCell>
                      <TableCell style={tableCellStyle}>
                        {paymentInfo.payment.stripe_payment_method_last_four ===
                        ""
                          ? "N/A"
                          : paymentInfo.payment.stripe_payment_method_last_four}
                      </TableCell>
                      <TableCell style={tableCellStyle}>
                        {USDollar.format(paymentInfo.payment.amount)}
                      </TableCell>
                      <TableCell style={tableCellStyle}>
                        {USDollar.format(paymentInfo.payment.amount_applied)}
                      </TableCell>
                      <TableCell style={tableCellStyle}>
                        {USDollar.format(paymentInfo.payment.amount_refunded)}
                      </TableCell>
                      <TableCell style={tableCellStyle}>
                        {USDollar.format(
                          paymentInfo.payment.amount_remaining_to_apply
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        ) : (
          <></>
        )}

        <Grid item xs={12}>
          <Typography variant="h5">Payment Allocation</Typography>
          {paymentInfo.paymentHistory.length === 0 ? (
            <Typography variant="body1">No Payment History</Typography>
          ) : (
            <TableContainer>
              <Table
                sx={{
                  minWidth: 500,
                  fontSize: 20,
                }}
                // size="small"
                aria-label="a dense table"
              >
                <colgroup>
                  <col width="25%" />
                  <col width="25%" />
                  <col width="25%" />
                  <col width="25%" />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell style={tableHeaderStyle}>
                      {historyByInvoice ? "Payment" : "Invoice"}
                    </TableCell>
                    <TableCell style={tableHeaderStyle}>{`${
                      historyByInvoice ? "Payment" : "Invoice"
                    } Date`}</TableCell>
                    <TableCell style={tableHeaderStyle}>Activity</TableCell>
                    <TableCell style={tableHeaderStyle}>Updated</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paymentInfo.paymentHistory.map((ph: PaymentHistoryType) => {
                    return (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell style={tableCellStyle}>
                          {historyByInvoice ? (
                            <>{getDisplayId(ph.payment_id)} </>
                          ) : (
                            <Tooltip title={"View Invoice Details "}>
                              <Link
                                style={{
                                  textDecoration: "underline",
                                  color: "black",
                                }}
                                to={`/billing/invoice/${ph.invoice_id}`}
                              >
                                {getDisplayId(ph.invoice_id)}
                              </Link>
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell style={tableCellStyle}>
                          {dayjs(ph.created_on).format("MM/DD/YY")}
                        </TableCell>
                        <TableCell style={tableCellStyle}>
                          {USDollar.format(ph.amount_paid)}
                        </TableCell>
                        <TableCell style={tableCellStyle}>
                          {dayjs(ph.updated_on).format("MM/DD/YY")}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </>
  );
}
