import React from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";

/* --- MUI Imports --- */
import { Button, Divider, Grid, Stack } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import SaveIcon from "@mui/icons-material/Save";

/* --- Project Imports --- */
import { defaultNullUndefined } from "../../../shared/globalFunctions";
import {
  isFieldValidForSubmit,
  setFieldConfigsToEdit,
} from "../../../shared/DynamicFormUtils";

// import { useAppSelector } from "../../../state/hooks";
// import { selectClientData, selectContactData } from "../../../state/slices/auth";
import DisplayIntakeField from "./DisplayIntakeField";
import {
  clientPortalFormKeys,
  FormFieldOptionsResponseType,
  FormResponseType,
  UpdateFieldResponseObject,
  updateFormResponse,
  UpdateFormResponsePayload,
} from "../../../apis/internalDb/forms/forms";
import SubmissionError from "../../../shared/ReusableComponents/SubmissionError";
import ConfirmationDialog from "../../../shared/ReusableComponents/ConfirmationDialog";
import {
  CUSTOM_FORM_STATUS_DRAFT_ID,
  CUSTOM_FORM_STATUS_SUBMIT_ID,
} from "../../../shared/resources/referenceConstants";

export interface CustomFormFieldDetails {
  custom_form_field_id: number;
  form_field_type: string;
  field_label: string;
  is_required: boolean;
  signature: string | null;
  max_char: number | null;
  custom_form_field_options: CustomFormFieldOptions[] | null;
  is_active: boolean;
}

export interface CustomFormFieldOptions {
  custom_form_field_options_id: number;
  label: string;
  sort_order: number;
  ref_id: number;
  ref_type: string;
  is_active: boolean;
}

interface DocumentFormProps {
  formData: FormResponseType;
  onClose: Function;
  isEdit: boolean;
  selectedClientId: number;
}

interface ResponseStateType {
  field_value: string;
  selected: boolean[];
}

export default function DocumentForm({
  formData,
  onClose,
  isEdit,
  selectedClientId,
}: DocumentFormProps) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [showFormErrors, setShowFormErrors] = React.useState<boolean>(false);
  const [isExit, setIsExit] = React.useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isSubmitError, setIsSubmitError] = React.useState<boolean>(false);
  const [serverErrorMessage, setServerErrorMessage] = React.useState<
    string | undefined
  >("");
  let retreivedFormFieldDetails: CustomFormFieldDetails[] = [];
  let retreivedFormFieldOptions: CustomFormFieldOptions[] = [];

  formData?.custom_form_field_response.map((field, index) => {
    field?.custom_form_field_options_response.map((option, optionsIndex) => {
      retreivedFormFieldOptions.push({
        label: option.label,
        custom_form_field_options_id:
          option.custom_form_field_options_response_id,
        sort_order: option.sort_order,
        ref_id: 0,
        ref_type: "",
        is_active: true,
      });
      return 0;
    });
    retreivedFormFieldDetails.push({
      custom_form_field_id: field.custom_form_response_id,
      form_field_type: field.form_field_type,
      field_label: field.field_label,
      is_required: field.is_required,
      signature: field.signature,
      max_char: field.max_char,
      custom_form_field_options: retreivedFormFieldOptions,
      is_active: true,
    });
    retreivedFormFieldOptions = [];
    return 0;
  });

  const fieldConfigs = setFieldConfigsToEdit(retreivedFormFieldDetails);
  let initialResponseState: ResponseStateType[] = [];

  formData.custom_form_field_response.forEach(function (item) {
    initialResponseState.push({
      field_value:
        item.form_field_type === "signature"
          ? defaultNullUndefined(item.signature, "")
          : item.field_response,
      selected: item.custom_form_field_options_response.map(
        (option: FormFieldOptionsResponseType) => {
          return defaultNullUndefined(option.selected, false);
        }
      ),
    });
  });

  const [formResponses, setFormResponses] =
    React.useState(initialResponseState);

  const handleSuccess = () => {
    navigate(-1);
  };

  const handleFieldChange = (
    value: string,
    index: number,
    checkIndex?: number
  ) => {
    if (checkIndex === undefined) {
      let tempFormResponses = [...formResponses];
      let tempResponse = formResponses[index].field_value;
      tempResponse = value;
      tempFormResponses[index].field_value = tempResponse;
      setFormResponses(tempFormResponses);
    } else {
      let tempFormResponses = [...formResponses];
      let tempResponse = formResponses[index].selected[checkIndex];
      tempResponse = Boolean(value);
      tempFormResponses[index].selected[checkIndex] = tempResponse;
      setFormResponses(tempFormResponses);
    }
  };

  const updateFormResponseMutation = useMutation(updateFormResponse, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: clientPortalFormKeys.all,
      });
      handleSuccess();
    },
    onError: (data: any) => {
      setIsSubmitError(true);
      setServerErrorMessage(data.response.data.results);
    },
  });

  const checkFormValidity = (errorMessages: string[][]) => {
    let formValid: boolean = true;
    errorMessages.forEach((fieldErrors: string[]) => {
      fieldErrors.forEach((error) => {
        if (error !== "") {
          return (formValid = false);
        }
      });
    });
    return formValid;
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (draft: boolean) => {
    let formValid = checkFormValidity(errorMessages);
    if (formValid || draft) {
      let formResponsesForPayload: UpdateFieldResponseObject[] =
        formData.custom_form_field_response.map((field, index) => {
          return {
            id: field.custom_form_field_response_id,
            field_response: formResponses[index].field_value,
            isSignature: field.form_field_type === "signature",
            field_options_response:
              field.custom_form_field_options_response.map(
                (option: FormFieldOptionsResponseType, optionIndex: number) => {
                  return {
                    id: option.custom_form_field_options_response_id,
                    option: formResponses[index].selected[optionIndex],
                  };
                }
              ),
          };
        });

      let updatePayload: UpdateFormResponsePayload = {
        responseId: defaultNullUndefined(formData.custom_form_response_id, 0),
        responses: formResponsesForPayload,
        statusId: draft
          ? CUSTOM_FORM_STATUS_DRAFT_ID
          : CUSTOM_FORM_STATUS_SUBMIT_ID,
        selectedClientId: selectedClientId,
        userId: selectedClientId.toString(),
      };
      updateFormResponseMutation.mutate(updatePayload);
    } else {
      setShowFormErrors(true);
      setIsSubmitting(false);
    }
    return;
  };

  const errorMessages: string[][] = fieldConfigs.map((fc, index) =>
    isFieldValidForSubmit(fc, formResponses[index].field_value)
  );

  return (
    <form>
      {isSubmitError && (
        <SubmissionError
          showDefaultMessage={false}
          additionalMessage={serverErrorMessage}
        />
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack spacing={2} paddingBottom={2} paddingTop={1}>
          {fieldConfigs.map((field, index) => (
            <DisplayIntakeField
              key={`field${field.id}`}
              isDisabled={!isEdit}
              fieldConfig={field}
              index={index}
              fieldValue={formResponses[index].field_value}
              isError={showFormErrors && errorMessages[index].length !== 0}
              handleChange={handleFieldChange}
              errorMessage={errorMessages[index]}
            />
          ))}
        </Stack>
      </LocalizationProvider>
      <Divider />
      <Grid padding={2} container justifyContent="flex-end">
        <Stack direction="row" spacing={2}>
          <Button
            color="secondary"
            variant="outlined"
            autoFocus
            onClick={() => {
              if (!isEdit) {
                handleClose();
              } else {
                setIsExit(true);
              }
            }}
          >
            Exit
          </Button>
          {isEdit && (
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={() => {
                setShowFormErrors(false);
                handleSubmit(true);
              }}
            >
              Save as Draft
            </Button>
          )}
          {isEdit && (
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={() => {
                setShowFormErrors(false);
                setIsSubmitting(true);
                handleSubmit(false);
              }}
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          )}
        </Stack>
      </Grid>
      {isExit && isEdit && (
        <ConfirmationDialog
          isOpen={isExit}
          title="Are you sure?"
          message="Any changes you have made will not be saved."
          onCancel={() => {
            setIsExit(false);
          }}
          onConfirm={handleClose}
        />
      )}
    </form>
  );
}
